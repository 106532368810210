.tooltip-content {
  font-size: 1rem;
  color: #000000; //clrBlack
  max-width: 12vw;
  display: flex;
  flex-wrap: wrap;
}

.tooltip-comp {
  background-color: #ffb400 !important;
}
